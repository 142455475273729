import React from "react";
import { useLocation } from "react-router-dom";
import NavDashboard from "./nav/NavDashboard";
import NavAccount from "./nav/NavAccount";
import NavBusiness from "./nav/NavBusiness";

export default function NavigationComponent() {

    const location = useLocation();
    const navkey = location.pathname.replace('/ux/', '/').split("/")[1].toLowerCase();

    return (navkey === 'business') ? (<NavBusiness />) 
        : (navkey === 'account') ? (<NavAccount />) 
        : (<NavDashboard />);
}
