import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { TitleCase } from "../Util/Functions";

interface Dictionary {
    [key: string]: string;
}

const namelib: Dictionary = {
    "/ux": "Dashboard",
    "/ux/business/summary": "Business Summary",
    "/ux/business/details": "Business Details",
    "/ux/business/settings": "Default Settings",
    "/ux/business/bulkactivations": "Bulk Activations",
    "/ux/business/pages": "Business Pages",
    "/ux/business/payteam": "Pay Team",
    "/ux/business/shoppingcart": "Shopping Cart",
    "/ux/business/salestax": "Sales Tax",
    "/ux/business/productfees": "Product Fees",
    "/ux/business/marketareas": "Market Areas",
    "/ux/business/serviceareas": "Service Areas",
    "/ux/business/siteattribution": "Site Attribution",
    "/ux/business/customdesigns": "Custom Designs",
    "/ux/business/emailmessages": "Email Messages",
    "/ux/business/mobileapp": "Mobile App",
    "/ux/business/appintegrations": "App Integrations",
    "/ux/business/webhooksapis": "Webhooks & APIs",
    "/ux/business/referralcode": "Referral Code",
    "/ux/account/basic": "Basic Data",
    "/ux/account/listingpages": "Listings Pages",
    "/ux/account/paymenttypes": "Payment Types",
    "/ux/account/password": "Update Password",
    "/ux/account/appintegrations": "App Integrations",
    "/ux/account/serviceareas": "Service Areas",
}

export default function BreadcrumbsComponent() {

    const location = useLocation();
    const paths = location.pathname.substring(1).split("/");
    let url = '';

    return (paths.length > 1) ? (
        <Breadcrumbs aria-label="breadcrumb">
            {
                paths.map((path: string, index: number) => {
                    url += '/' + path;
                    return (index < paths.length - 1) ? (
                        <Link to={url} className="hover-link" key={index}>
                            <Typography variant="body2">{namelib[url.toLowerCase()] || TitleCase(path)}</Typography>
                        </Link>
                    ) : (
                        <Typography variant="body2" key={index}>{namelib[url.toLowerCase()] || TitleCase(path)}</Typography>
                    )
                })
            }
        </Breadcrumbs>
    ) : null
}
