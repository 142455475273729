import React from "react";
import { Avatar, Button } from "@mui/material";
import { ArrowDown01Icon } from "hugeicons-react";

export default function ProfileMenu() {

    const name = "Turner Key";
    const src = "https://media.hd.pics/2/aa083yd5aa.jpg?w=60";

    return (
    <Button
        startIcon={
            <Avatar
                src={src}
                alt={name}
                sx={{ width: 24, height: 24 }}
            />
        }
        endIcon={<ArrowDown01Icon size={12} />}
        sx={{
            borderRadius: 100,
            background: "var(--common-white_states-main, #FFF)",
            padding: '4px 10px 4px 8px'
        }}
    >
        {name}
    </Button>
    )
}
