import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { ArrowTurnBackwardIcon, QuestionIcon } from "hugeicons-react";

export default function NavSites() {
    return (
        <>
            <ListItem component={Link} to="/ux">
                <ListItemIcon>
                    <ArrowTurnBackwardIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/Basic" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Basic Data" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/ListingPages" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Listings Pages" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/Reports" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/PaymentTypes" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Payment Types" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/Password" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Update Password" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/AppIntegrations" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="App Integrations" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account/ServiceAreas" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Service Areas" />
            </ListItem>
        </>
    );
};
