import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { QuestionIcon } from "hugeicons-react";

export default function NavDashboard() {
    return (
        <>
            <ListItem component={NavLink} to="/ux/Dashboard" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Sites" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Sites" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Clients" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Clients" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Groups" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Groups" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Businesses" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Businesses" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Prospects" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Prospects" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Business" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Account" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Account" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Admin" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <QuestionIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Admin" />
            </ListItem>
        </>
    );
};
