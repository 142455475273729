import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavigationComponent from "./NavigationComponent";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import ProfileMenu from "./ProfileMenu";

export default function LayoutComponent() {
    return (
        <Box display="flex" height="100vh" bgcolor="var(--background-default-content, #ECEFF1)">
            <Box
                display="flex"
                flexDirection="column"
                width={252}
                bgcolor="var(--background-default, #FFF)"
                borderRight="1px solid var(--elevation-outlined, #E0E0E0)"
            >
                <Box display="flex" alignItems="center" p={2}>
                    <img
                        src="/art/HDPhotoHub_400.png"
                        alt="Customer Logo"
                        style={{ width: "100%", height: "auto" }}
                    />
                </Box>
                <Box flex={1} overflow={"hidden auto"} p={2} pt={1}>
                    <NavigationComponent />
                </Box>
            </Box>

            <Box flex={1} display="flex" flexDirection="column" position="relative">
                <Box p="var(--1, 8px) var(--3, 24px)">
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <BreadcrumbsComponent />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <ProfileMenu />
                        </Box>
                    </Box>
                </Box>
                <Box
                    flex={1} 
                    overflow={"hidden auto"}
                    p="var(--1, 8px)  var(--3, 24px) var(--3, 24px) var(--3, 24px)"
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};
