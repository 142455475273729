import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { ArrowTurnBackwardIcon, DashboardSquareAddIcon, LicenseIcon, Settings01Icon, Logout04Icon, Analytics01Icon, File01Icon, 
    CreditCardIcon, ShoppingCart01Icon, Calculator01Icon, DollarSquareIcon, City01Icon, MapsIcon, Award05Icon, PaintBrush03Icon, 
    Mail02Icon, GlobalIcon, SmartPhone01Icon, Key01Icon, BlendIcon, SourceCodeIcon, FavouriteIcon
 } from "hugeicons-react";

export default function NavBusiness() {
    return (
        <>
            <ListItem component={Link} to="/ux">
                <ListItemIcon>
                    <ArrowTurnBackwardIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Summary" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <DashboardSquareAddIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Business Summary" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Details" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <LicenseIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Business Details" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Settings" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Settings01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Default Settings" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/BulkActivations" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Logout04Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Bulk Activations" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Reports" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Analytics01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Pages" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <File01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Business Pages" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/PayTeam" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <CreditCardIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Pay Team" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/ShoppingCart" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <ShoppingCart01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Shopping Cart" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/SalesTax" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Calculator01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Sales Tax" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/ProductFees" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <DollarSquareIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Product Fees" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/MarketAreas" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <City01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Market Areas" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/ServiceAreas" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <MapsIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Service Areas" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/SiteAttribution" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Award05Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Site Attribution" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/CustomDesigns" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <PaintBrush03Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Custom Designs" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/EmailMessages" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Mail02Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Email Messages" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Domains" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <GlobalIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Domains" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/MobileApp" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <SmartPhone01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Mobile App" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/Permissions" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <Key01Icon size={20} />
                </ListItemIcon>
                <ListItemText primary="Permissions" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/AppIntegrations" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <BlendIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="App Integrations" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/WebhooksAPIs" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <SourceCodeIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Webhooks/APIs" />
            </ListItem>
            <ListItem component={NavLink} to="/ux/Business/ReferralCode" className="{({ isActive }) => isActive ? 'active' : ''}">
                <ListItemIcon>
                    <FavouriteIcon size={20} />
                </ListItemIcon>
                <ListItemText primary="Referral Code" />
            </ListItem>
        </>
    );
};
