import React from "react";
import {
    CssBaseline,
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material";

const appTheme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    "&": {
                        borderRadius: "calc(var(--border-radius, 8px) / 2)"
                    },
                    '&:hover': {
                        backgroundColor: "var(--action-hover, rgba(0, 0, 0, 0.04))"
                    },
                    "&.active": {
                        background: "var(--primary-_states-focus, rgba(25, 118, 210, 0.12))"
                    },
                    "&.active .MuiListItemIcon-root": {
                        color: "var(--primary-dark, #0288D1)",
                    },
                    "&.active .MuiListItemText-primary": {
                        color: "var(--primary-dark, #1565C0)",
                        fontfeaturesettings: "'liga' off, 'clig' off"
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    "&": {
                        minWidth: 36
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: "var(--font-size-125-rem, 20px)"
                    },
                },
            },
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    color: "var(--text-primary, rgba(0, 0, 0, 0.87));",
                    fontfeaturesettings: "'liga' off, 'clig' off",
                    fontFamily: "var(--fontFamily, Roboto)",
                    fontSize: "var(--font-size-0875-rem, 14px)",
                    fontStyle: "normal",
                    fontWeight: "var(--fontWeightRegular, 400)",
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    textDecoration: "none"
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& input": {
                        color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                        fontfeaturesettings: "'liga' off, 'clig' off",
                        fontFamily: "var(--fontFamily, Roboto)",
                        fontSize: "var(--_fontSize-1rem, 16px)",
                        fontStyle: "normal",
                        fontWeight: "var(--fontWeightRegular, 400)",
                        lineHeight: "24px",
                        letterSpacing: "0.15px"
                    },
                },
            },
        }
    }
  });

export default function ThemeProvider({ children }) {
    return (
        <MuiThemeProvider theme={appTheme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
};
