
export default function Http404() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', background: 'radial-gradient(#fff, #ccc)' }}>
        <div style={{ fontSize: 'calc(min(8vh, 8vw))', lineHeight: '1.6em', textAlign: 'center', marginBottom: '1em' }}>
            <div style={{ color: '#888', display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                <div>H</div>
                <div>T</div>
                <div>T</div>
                <div>P</div>
            </div>
            <div style={{ color: '#333', fontSize: '2.5em', fontWeight: 700, letterSpacing: '0.2em', marginRight: '-0.2em' }}>404</div>
        </div>
      </div>
    );
}
