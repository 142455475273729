import React from 'react';
import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import ThemeProvider from './Components/ThemeProvider';
import Http404 from './Pages/http404';
import LayoutComponent from './Components/LayoutComponent';
import BusinessDetails from './Pages/Business/BusinessDetails';
import LegacyPage from './Components/LegacyPage';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <Http404 />
    },
    {
      path: "/ux",
      element: <LayoutComponent />,
      children: [
        {
          path: "/ux",
          element: <Navigate to="/ux/Dashboard" />
        },
        {
          path: "/ux/Dashboard",
          element: <LegacyPage title="Dashboard" src="/dashboard/default.asp" />
        },
        {
          path: "/ux/Sites",
          element: <LegacyPage title="Sites" src="/sites/search.asp" />
        },
        {
          path: "/ux/Clients",
          element: <LegacyPage title="Clients" src="/clients/search.asp" />
        },
        {
          path: "/ux/Groups",
          element: <LegacyPage title="Groups" src="/groups/search.asp" />
        },
        {
          path: "/ux/Businesses",
          element: <LegacyPage title="Businesses" src="/brand/brands.asp" />
        },
        {
          path: "/ux/Prospects",
          element: <LegacyPage title="Prospects" src="/prospects/" />
        },
        {
          path: "/ux/Groups",
          element: <LegacyPage title="Groups" src="/groups/search.asp" />
        },
        {
          path: "/ux/Business",
          element: <Navigate to="/ux/Business/Summary" />
        },
        {
          path: "/ux/Business/Summary",
          element: <LegacyPage title="Business Summary" src="/brand/default.asp" />
        },
        {
          path: "/ux/Business/Details",
          element: <BusinessDetails />
        },
        {
          path: "/ux/Business/Settings",
          element: <LegacyPage title="Business Settings" src="/brand/defaults.asp" />
        },
        {
          path: "/ux/Business/BulkActivations",
          element: <LegacyPage title="Bulk Activations" src="/brand/BulkActivation.asp" />
        },
        {
          path: "/ux/Business/Reports",
          element: <LegacyPage title="Reports" src="/brand/Reports.asp" />
        },
        {
          path: "/ux/Business/Pages",
          element: <LegacyPage title="Business Pages" src="/brand/Pages.asp" />
        },
        {
          path: "/ux/Business/PayTeam",
          element: <LegacyPage title="Pay Team" src="/brand/Payout.asp" />
        },
        {
          path: "/ux/Business/ShoppingCart",
          element: <LegacyPage title="Shopping Cart" src="/brand/Cart.asp" />
        },
        {
          path: "/ux/Business/SalesTax",
          element: <LegacyPage title="Sales Tax" src="/brand/SalesTax.asp" />
        },
        {
          path: "/ux/Business/ProductFees",
          element: <LegacyPage title="Product Fees" src="/brand/Fees.asp" />
        },
        {
          path: "/ux/Business/MarketAreas",
          element: <LegacyPage title="Market Areas" src="/brand/Markets.asp" />
        },
        {
          path: "/ux/Business/ServiceAreas",
          element: <LegacyPage title="Service Areas" src="/brand/ServiceAreas.asp" />
        },
        {
          path: "/ux/Business/SiteAttribution",
          element: <LegacyPage title="Site Attribution" src="/brand/Sponsors.asp" />
        },
        {
          path: "/ux/Business/CustomDesigns",
          element: <LegacyPage title="Custom Designs" src="/brand/Designs.asp" />
        },
        {
          path: "/ux/Business/EmailMessages",
          element: <LegacyPage title="Email Messages" src="/brand/Messages.asp" />
        },
        {
          path: "/ux/Business/Domains",
          element: <LegacyPage title="Domains" src="/brand/Domains.asp" />
        },
        {
          path: "/ux/Business/MobileApp",
          element: <LegacyPage title="Mobile App" src="/brand/MobileApp.asp" />
        },
        {
          path: "/ux/Business/Permissions",
          element: <LegacyPage title="Permissions" src="/brand/Permissions.asp" />
        },
        {
          path: "/ux/Business/AppIntegrations",
          element: <LegacyPage title="Permissions" src="/brand/apps.asp" />
        },
        {
          path: "/ux/Business/WebhooksAPIs",
          element: <LegacyPage title="Webhooks & APIs" src="/brand/api.asp" />
        },
        {
          path: "/ux/Business/ReferralCode",
          element: <LegacyPage title="Referral Code" src="/brand/referral.asp" />
        },
        {
          path: "/ux/Account",
          element: <Navigate to="/ux/Account/Basic" />
        },
        {
          path: "/ux/Account/Basic",
          element: <LegacyPage title="Account Basics" src="/account/default.asp" />
        },
        {
          path: "/ux/Account/ListingPages",
          element: <LegacyPage title="Listing Pages" src="/account/UserSites.asp" />
        },
        {
          path: "/ux/Account/Reports",
          element: <LegacyPage title="Account Reports" src="/account/Reports.asp" />
        },
        {
          path: "/ux/Account/PaymentTypes",
          element: <LegacyPage title="Payment Types" src="/account/PaymentTypes.asp" />
        },
        {
          path: "/ux/Account/Password",
          element: <LegacyPage title="Password" src="/account/UpdatePassword.asp" />
        },
        {
          path: "/ux/Account/AppIntegrations",
          element: <LegacyPage title="App Integrations" src="/account/apps.asp" />
        },
        {
          path: "/ux/Account/ServiceAreas",
          element: <LegacyPage title="Service Areas" src="/account/ServiceAreas.asp" />
        },
        {
          path: "/ux/Admin",
          element: <LegacyPage title="Admin" src="/admin/default.asp" />
        }
      ]
    },
  ])

  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
