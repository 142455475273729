import React from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";

export default function BusinessDetails() {
    return (
        <Box>
            <Box maxWidth="md" sx={{ p: 0, ml: 0 }}>
                <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Details
                    </Typography>
                    <TextField
                        fullWidth
                        label="Business Name"
                        defaultValue="Blue VisionPro Photography"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Public Phone Number"
                        defaultValue="503-255-1822"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Public Email"
                        defaultValue="tim@bluevisionpro.com"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                </Paper>

                <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Social Media
                    </Typography>
                    <TextField
                        fullWidth
                        label="LinkedIn"
                        defaultValue="https://www.linkedin.com/in/yourprofile/"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Facebook"
                        defaultValue="https://www.facebook.com/profile.php?id=61564368120747"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Twitter/X"
                        defaultValue="https://x.com/yourhandle"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Instagram"
                        defaultValue="https://instagram.com/yourhandle"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                </Paper>

                <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Business Address
                    </Typography>
                    <TextField
                        fullWidth
                        label="Street"
                        defaultValue="Your business address"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Street continued"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined" margin="normal" size="small">
                                <InputLabel>State</InputLabel>
                                <Select defaultValue="OR" label="State">
                                    <MenuItem value="OR">OR</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Zip"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Client Dashboard
                    </Typography>
                    <TextField
                        fullWidth
                        label="Welcome message to clients"
                        defaultValue="Blue VisionPro Photography"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        label="Order Page URL"
                        defaultValue="https://hdph.hd.pics/order-demo"
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                </Paper>

                <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Invoice
                    </Typography>
                    <TextField
                        fullWidth
                        label="Note to display on invoices"
                        defaultValue="Payments are due 30 days from invoice date."
                        variant="outlined"
                        margin="normal"
                        size="small"
                    />
                </Paper>

                <Paper elevation={1} sx={{ p: 3, mb: 9 }}>
                    <Typography variant="h6" gutterBottom>
                        Copyright License
                    </Typography>
                    <TextField
                        fullWidth
                        label="License for use of photography"
                        defaultValue="Copyright notice: The photographer [TeamMember], who has taken the images and videos is the copyright holder. [Client] does not have copyright ownership. Thanks!"
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <Typography variant="body2" color="textSecondary" mt={2}>
                        Customize tags: [FullAddress] [Client] [Group] [TeamMember]
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Require acknowledgment to download photos"
                    />
                </Paper>
            </Box>

            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                p={2}
                bgcolor="white"
                boxShadow={24}
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                zIndex={99}
            >
                <Button variant="outlined" sx={{ mr: 2 }}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary">
                    Save
                </Button>
            </Box>
        </Box>
    );
};
